import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiUrls } from 'src/app/core/classes/ApiUrls';
import { CustomValidators } from 'src/app/shared/classes/CustomValidators';
import { HitApi } from 'src/app/shared/classes/HitApi';
import { Widget } from 'src/app/shared/classes/Widget';
import { FormGeneratorModalComponent } from 'src/app/shared/components/modal-templates/form-generator-modal/form-generator-modal.component';
import { OtpVerificationModalComponent } from 'src/app/shared/components/modal-templates/otp-verification-modal/otp-verification-modal/otp-verification-modal.component';
import { ActionState } from 'src/app/shared/enums/ActionState';
import { ActionVisibility } from 'src/app/shared/enums/ActionVisibility';
import { AuthorizationType } from 'src/app/shared/enums/AuthorizationType';
import { ButtonColorType } from 'src/app/shared/enums/ButtonColorType';
import { ButtonType } from 'src/app/shared/enums/ButtonType';
import { FilterType } from 'src/app/shared/enums/FilterType';
import { FormState } from 'src/app/shared/enums/FormState';
import { IconType } from 'src/app/shared/enums/IconType';
import { ModalType } from 'src/app/shared/enums/ModalType';
import { RequestType } from 'src/app/shared/enums/RequestType';
import { IAction } from 'src/app/shared/interfaces/actions/IAction';
import { IButtonGeneratorInput } from 'src/app/shared/interfaces/button-generator/IButtonGeneratorInput';
import { IModalData } from 'src/app/shared/interfaces/modal/IModalData';
import { Helper } from './../../../../../classes/Helper';
import { WidgetInjectedData } from './../../../../../classes/WidgetInjectedData';
import { IFormGeneratorInput } from './../../../../../interfaces/form-generator/IFormGeneratorInput';
import { IHitApi } from './../../../../../interfaces/hit-api/IHitApi';
import { UserDataCacheService } from './../../../../../services/user-data-cache/user-data-cache.service';

@Component({
    selector: 'app-mfa',
    templateUrl: './mfa.component.html',
    styleUrls: ['./mfa.component.sass']
})
export class MfaComponent implements OnInit {
    widgetRef: Widget;
    mfaFormGenInputs: IFormGeneratorInput;
    mfaFormGroup: FormGroup;
    credentialFormGenInputs: IFormGeneratorInput;
    passwordFormGenInputs: IFormGeneratorInput;
    passwordFormGroup: FormGroup;
    FormState = FormState;
    mfaState: boolean;
    resetMfaInfo: any;
    callbackFn: any;
    verificationSuccessful: boolean = false;
    root: boolean;

    saveButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Save',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.FLAT,
        function: null
    };

    sendOTPButtonGenInput: IButtonGeneratorInput = {
        buttonName: 'Send OTP',
        buttonColorType: ButtonColorType.PRIMARY,
        buttonType: ButtonType.RAISED,
        function: null
    };

    constructor(
        private widgetData: WidgetInjectedData,
        private userDataCache: UserDataCacheService,
        private cd: ChangeDetectorRef
    ) {
        this.mfaState = this.userDataCache.mfaStatus;
        this.root = this.userDataCache.rootUser;
    }

    ngOnInit(): void {
        this.widgetRef = this.widgetData.widgetRef;
        this.setUpBasics();
    }

    setUpBasics() {
        // update MFA section
        const actions: IAction[] = [];
        actions.push({
            state: this.userDataCache.rootUser
                ? ActionState.ENABLED
                : ActionState.DISABLED,
            visibility: ActionVisibility.VISIBLE,
            message: '',
            function: this.toggleMfa.bind(this),
            icon: {
                type: IconType.FONTAWSOME,
                text: this.mfaState ? 'Disable MFA' : 'Enable MFA'
            },
            actionId: this.mfaState ? 'Disable MFA' : 'Enable MFA'
        });

        this.widgetRef.operationalActions.value.set('MFA Config', actions);

        this.widgetRef.operationalActions.next(
            this.widgetRef.operationalActions.value
        );
        // Update Header Icon
        this.widgetRef.headerIcon.next({
            class: 'fas fa-user-alt',
            type: IconType.FONTAWSOME
        });
    }
    bindData(data) {
        this.mfaFormGenInputs = {
            formName: '',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    label: 'Authentication Type',
                    placeholder: 'Authentication Type',
                    name: 'mfaType',
                    fieldType: FilterType.RADIO,
                    listData: [
                        { id: 'EMAIL', label: 'EMAIL' },
                        // { id: 'PHONE_NUMBER', label: 'SMS' }
                    ],
                    value: data && data.mfaType ? data.mfaType : 'EMAIL',
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Authentication is required'
                        }
                    ]
                },
                {
                    label: 'Email',
                    placeholder: 'Enter Email',
                    name: 'email',
                    fieldType: FilterType.EMAIL,
                    value:
                        data && data.email
                            ? data.email
                            : this.userDataCache.emailId,
                    required: true,
                    disabled: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Email is required.'
                        },
                        {
                            validator: CustomValidators.email,
                            errorMessage: 'Invalid Email.'
                        }
                    ],
                    hiddenDependency: [
                        {
                            controlName: 'mfaType',
                            validations: [
                                {
                                    validator: CustomValidators.required
                                },
                                {
                                    validator:
                                        CustomValidators.controlValueMatch(
                                            'EMAIL'
                                        )
                                }
                            ]
                        }
                    ]
                }
            ]
        };
        this.credentialFormGenInputs = {
            formName: '',
            state: FormState.IDLE,
            submitButton: null,
            fields: [
                {
                    label: 'Password',
                    placeholder: 'Enter Password',
                    name: 'creds',
                    fieldType: FilterType.TEXT,
                    value: '**********'
                }
            ]
        };
        this.passwordFormGenInputs = {
            formName: '',
            state: FormState.EDIT,
            submitButton: null,
            fields: [
                {
                    label: 'Password',
                    placeholder: 'Enter your Login Password',
                    name: 'password',
                    fieldType: FilterType.PASSWORD,
                    required: true,
                    validations: [
                        {
                            validator: CustomValidators.required,
                            errorMessage: 'Password is required'
                        }
                    ]
                }
            ]
        };
        this.verificationSuccessful = false;
        this.widgetRef.endLoader();
    }
    changeState(state: FormState) {
        if (this.verificationSuccessful) {
            return;
        }
        this.mfaFormGenInputs.state = state;
        this.mfaFormGenInputs = Helper.dereference(this.mfaFormGenInputs);
    }
    changeCredsState(state: FormState) {
        this.credentialFormGenInputs.state = state;
        this.credentialFormGenInputs = Helper.dereference(
            this.credentialFormGenInputs
        );
    }
    otpVerification() {
        const fieldVal = this.mfaFormGroup.get('mfaType').value;
        // if (fieldVal === 'PHONE_NUMBER') {
        //     Helper.markAllFieldAsTouched(this.mfaFormGroup);
        //     if (this.mfaFormGroup.invalid) {
        //         this.mfaFormGroup.updateValueAndValidity();
        //         return;
        //     }
        // }

        const input = {};
        if (this.mfaFormGroup) {
            if (fieldVal === 'EMAIL') {
                input['email'] = this.mfaFormGroup.get('email').value;
            } 
            // else {
            //     input['phoneNumber'] = parseInt(
            //         this.mfaFormGroup.get('phoneNumber').value
            //     );
            // }
        }
        const modalData: IModalData = {
            modalName: 'Multi Factor Authentication',
            modalIcon: {
                type: IconType.MATICON,
                class: 'verified_user'
            },
            sourceId: Symbol(),
            modalType: ModalType.MIDDLE,
            modalHeightVh: 42,
            modalWidthVw: 60,
            modalSteps: [
                {
                    stepData: {
                        componentToLoad: OtpVerificationModalComponent,
                        payload: {
                            data: {
                                function: this.verify.bind(this),
                                widgetRef: this.widgetRef,
                                input: input
                            }
                        }
                    },
                    stepName: 'Verification'
                }
            ]
        };
        this.widgetRef.modalService.openModal(modalData);
    }
    verify(modalId, formGroup: FormGroup, callbackFn) {
        if (callbackFn) {
            this.callbackFn = callbackFn;
        }

        Helper.markAllFieldAsTouched(formGroup);
        if (formGroup.invalid) {
            formGroup.updateValueAndValidity();
            this.callbackFn();
            return;
        }

        const code = formGroup.get('verificationCode').value;
        const apiConfig: IHitApi = {
            url: `${
                this.root
                    ? ApiUrls.VERIFY_OTP
                    : ApiUrls.VERIFY_OTP_NON_ROOT_USER
            }/${code}`,
            intactUrl: `${
                this.root
                    ? ApiUrls.VERIFY_OTP
                    : ApiUrls.VERIFY_OTP_NON_ROOT_USER
            }/{code}`,
            input: {},
            requestType: RequestType.POST,
            uniqueIdentity: Symbol(),
            config: {
                authorization: AuthorizationType.BEARER_TOKEN
            },
            function: () => {
                this.changeState(FormState.IDLE);
                this.changeCredsState(FormState.CREATE);
                this.cd.detectChanges();
                this.verificationSuccessful = true;
                this.widgetRef.modalService.closeModal(null, modalId);
            },
            errorFunction: (error) => {
                this.callbackFn(error.error.message);
            }
        };
        new HitApi(
            apiConfig,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    enableMfa(buttonRef: IButtonGeneratorInput) {
        if (buttonRef.loader) {
            return;
        }
        Helper.markAllFieldAsTouched(this.mfaFormGroup);
        if (this.mfaFormGroup.invalid) {
            this.mfaFormGroup.updateValueAndValidity();
            return;
        }
        Helper.markAllFieldAsTouched(this.passwordFormGroup);
        if (this.passwordFormGroup.invalid) {
            this.passwordFormGroup.updateValueAndValidity();
            return;
        }
        buttonRef.loader = true;
        const apiArgs = Helper.generateHitApiConfig(
            this.widgetRef.widgetData.widgetInfo.create
        );
        apiArgs.input = this.prepareInput();
        apiArgs.function = () => {
            buttonRef.loader = false;
            this.widgetRef.notificationsService.showSnackBar(
                'MFA enabled successfully'
            );
            const actions: IAction[] = [];

            actions.push({
                state: ActionState.ENABLED,
                visibility: ActionVisibility.VISIBLE,
                message: '',
                function: this.toggleMfa.bind(this),
                icon: {
                    type: IconType.FONTAWSOME,
                    text: 'Disable MFA'
                }
            });

            this.widgetRef.operationalActions.value.set('MFA Config', actions);

            this.widgetRef.operationalActions.next(
                this.widgetRef.operationalActions.value
            );
            this.changeState(FormState.IDLE);
            this.changeCredsState(FormState.IDLE);
            this.cd.detectChanges();
            this.userDataCache.mfaStatus = true;
            this.verificationSuccessful = false;
            this.mfaState = true;
            this.widgetRef.refreshWidget();
        };
        apiArgs.errorFunction = (error) => {
            this.widgetRef.notificationsService.showSnackBar(
                error.error.message,
                true
            );
            buttonRef.loader = false;
            this.cd.detectChanges();
        };
        new HitApi(
            apiArgs,
            this.widgetRef.httpService,
            this.widgetRef.ngZone
        ).hitApi();
    }
    prepareInput() {
        const input = {
            // phoneNumber: this.mfaFormGroup.get('phoneNumber').value,
            mfaType: this.mfaFormGroup.get('mfaType').value,
            userRequestModel: {
                email: this.userDataCache.emailId,
                password: this.passwordFormGroup.get('password').value
            }
        };
        return input;
    }
    toggleMfa(state) {
        if (this.verificationSuccessful && state !== 'Disable MFA') {
            return;
        }
        if (state === 'Disable MFA') {
            const formGenInput: IFormGeneratorInput = {
                formName: '',
                state: FormState.CREATE,
                submitButton: {
                    buttonName: 'Submit',
                    showLoader: true,
                    buttonType: ButtonType.FLAT,
                    buttonColorType: ButtonColorType.PRIMARY,
                    function: (
                        buttonRef: IButtonGeneratorInput,
                        formGroup: FormGroup,
                        modalId: Symbol
                    ) => {
                        if (formGroup.valid) {
                            buttonRef.loader = true;
                            const args = Helper.generateHitApiConfig(
                                this.widgetRef.widgetData.widgetInfo.delete
                            );
                            args.input = {
                                email: this.userDataCache.emailId,
                                password: formGroup.get('password').value
                            };
                            args.function = () => {
                                this.widgetRef.notificationsService.showSnackBar(
                                    'MFA disabled successfully'
                                );
                                const actions: IAction[] = [];

                                actions.push({
                                    state: ActionState.ENABLED,
                                    visibility: ActionVisibility.VISIBLE,
                                    message: '',
                                    function: this.toggleMfa.bind(this),
                                    icon: {
                                        type: IconType.FONTAWSOME,
                                        text: 'Enable MFA'
                                    }
                                });

                                this.widgetRef.operationalActions.value.set(
                                    'MFA Config',
                                    actions
                                );

                                this.widgetRef.operationalActions.next(
                                    this.widgetRef.operationalActions.value
                                );
                                this.changeState(FormState.IDLE);
                                this.changeCredsState(FormState.IDLE);
                                this.userDataCache.mfaStatus = false;
                                buttonRef.loader = false;
                                this.widgetRef.refreshWidget();
                                this.widgetRef.modalService.closeModal(
                                    null,
                                    modalId
                                );
                                this.mfaState = false;
                            };
                            args.errorFunction = (error) => {
                                Helper.showErrorMessage(
                                    this.widgetRef.notificationsService,
                                    error
                                );
                                buttonRef.loader = false;
                            };
                            new HitApi(
                                args,
                                this.widgetRef.httpService,
                                this.widgetRef.ngZone
                            ).hitApi();
                        }
                    }
                },
                fields: [
                    {
                        label: 'Password',
                        placeholder: 'Enter your Login Password',
                        name: 'password',
                        fieldType: FilterType.PASSWORD,
                        required: true,
                        validations: Helper.getPasswordValidators()
                    }
                ]
            };
            const modalArgs: IModalData = {
                modalName: 'Disable MFA',
                sourceId: this.widgetRef.widgetData.widgetUniqueIdentifier,
                modalType: ModalType.MIDDLE,
                modalIcon: {
                    type: IconType.MATICON,
                    class: 'verified_user'
                },
                modalSteps: [
                    {
                        stepName: '',
                        stepData: {
                            componentToLoad: FormGeneratorModalComponent,
                            payload: {
                                data: formGenInput
                            }
                        }
                    }
                ],
                modalWidthVw: 50,
                modalHeightVh: 38
            };
            this.widgetRef.modalService.openModal(modalArgs);
        } else {
            this.changeState(FormState.CREATE);
        }
    }
    ngAfterViewInit(): void {
        this.widgetRef.setBindData(this.bindData.bind(this));
    }
}
